import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Input,
  Form,
  message,
  DatePicker,
  Select,
  Col,
  Row,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import axios from "../../utils/axios.js";
import moment from "moment-timezone";
import ls from "../../utils/localstorage";

import "./styles.scss";
import { API_URL } from "../../constants.js";
const { Option } = Select;
const { TextArea } = Input;

const AddInvoicesPopup = ({ visible, onClose, closePopup }) => {
  const [form] = Form.useForm();
  const [accounts, setAccounts] = useState([]);
  const [quantityTypes, setQuantityTypes] = useState([]);
  const [varietiesResponse, setVarietiesResponse] = useState([]);
  const [fields, setFields] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get("/account/getAccounts");
        const quantityResponse = await axios.get("/invoice/quantityTypes");
        const varietiesResponse = await axios.get("/variety/getVarities");
        setQuantityTypes(quantityResponse?.data?.quantityTypeEnum);
        setAccounts(response?.data);
        setVarietiesResponse(varietiesResponse?.data);
      } catch (error) {
        new Error("Error getting accounts!");
        console.log({ error });
      }
    })();

    return () => {
      setAccounts([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFinish = async (values) => {
    values.attributes = fields;
    try {
      const token = ls.get("user")?.accessToken;
      const invoiceResponse = await fetch(API_URL + "/invoice", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(values),
      });
      const blob = await invoiceResponse.blob();
      console.log({ invoiceResponse, blob });

      if (invoiceResponse?.ok) {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `invoice-${moment()}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        message.success("Invoices Added Successfully!");
      } else {
        throw new Error("Invoices not fetched");
      }
      onClose(false);
      form.resetFields();
    } catch (error) {
      message.error("Couldn't process the invoices");
      console.log({ error });
    }
  };

  const handleFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    message.error("Please check the form fields and try again.");
  };

  const validateFloatNumber = (_, value) => {
    if (+value === parseFloat(Number(value).toFixed(4))) {
      return Promise.resolve();
    }
    return Promise.reject(new Error("Please enter a valid weight"));
  };

  const addFields = () => {
    setFields([...fields, { name: "", amount: "", status: "" }]);
  };

  const handleFieldChange = (index, field, value) => {
    const newFields = [...fields];
    newFields[index][field] = value;
    setFields(newFields);
  };

  return (
    <Modal
      open={visible}
      title={"Add Invoices"}
      onCancel={() => closePopup(false)}
      footer={null}
      closeIcon={<CloseOutlined />}
      className="modal-container"
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleFinish}
        onFinishFailed={handleFinishFailed}
      >
        <Form.Item
          name="date"
          label="Date"
          initialValue={moment()}
          rules={[{ required: true, message: "Please select the date!" }]}
        >
          <DatePicker style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          name="type"
          label="Type"
          rules={[{ required: true, message: "Please select a type!" }]}
        >
          <Select placeholder="Select a Type" allowClear>
            {["Sale", "Purchase"].map((option) => (
              <Option key={option} value={option.toUpperCase()}>
                {option}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="accountId"
          label="Account"
          rules={[{ required: true, message: "Please select a account!" }]}
        >
          <Select placeholder="Select an account"
          allowClear
          showSearch
          filterOption={(input, option) =>
          option.children.toLowerCase().includes(input.toLowerCase())
          }>
            {accounts.map((option) => (
              <Option key={option._id} value={option._id}>
                {option.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="variety"
          label="Varieties"
          rules={[{ required: true, message: "Please select a variety!" }]}
        >
          <Select placeholder="Select an account" allowClear>
            {varietiesResponse.map((option) => (
              <Option key={option._id} value={option.name}>
                {option.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="quantity"
          label="Quantity"
          rules={[
            {
              pattern: /^-?\d+$/,
              message: "Tora 50 must be a number!",
              required: true,
            },
          ]}
        >
          <Input placeholder="Enter Quantity" />
        </Form.Item>
        <Form.Item
          name="quantityType"
          label="Quantity Type"
          rules={[
            { required: true, message: "Please select a Quantity Type!" },
          ]}
        >
          <Select placeholder="Select a Type" allowClear>
            {quantityTypes?.map((option) => (
              <Option key={option} value={option}>
                {option}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="weight"
          label="Weight (KG)"
          rules={[{ validator: validateFloatNumber, required: true }]}
        >
          <Input placeholder="Enter Weight" step="0.01" />
        </Form.Item>
        <Form.Item
          name="rate"
          label="Rate"
          rules={[
            {
              pattern: /^-?\d+$/,
              message: "Tora 50 must be a number!",
              required: true,
            },
          ]}
        >
          <Input placeholder="Enter Rate" />
        </Form.Item>
        <Form.Item
          name="description"
          label="Description"
          rules={[{ message: "Please enter a description!", required: true }]}
        >
          <TextArea placeholder="Enter description" rows={4} />
        </Form.Item>
        <Form.Item
          name="bardanaWeight"
          label="Bardana Weight (KG)"
          rules={[{ validator: validateFloatNumber }]}
        >
          <Input placeholder="Enter Bardana" />
        </Form.Item>
        <Form.Item
          name="weightDisc"
          label="Weight Discount (KG)"
          rules={[{ validator: validateFloatNumber }]}
        >
          <Input placeholder="Enter Weight Discount" />
        </Form.Item>
        <Form.Item name="vehicleNumber" label="Vehicle Number">
          <Input placeholder="Enter Vehicle Number" />
        </Form.Item>
        <Form.Item name="note" label="Note">
          <TextArea placeholder="Enter Note" rows={4} />
        </Form.Item>

        {fields.map((field, index) => (
          <Row gutter={16} key={index}>
            <Col span={8}>
              <Form.Item
                label={`Name ${index + 1}`}
                // name={`name${index}`}
                rules={[{ message: "Please enter a name" }]}
              >
                <Input
                  placeholder="Enter name"
                  onChange={(e) =>
                    handleFieldChange(index, "name", e.target.value)
                  }
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label={`Amount ${index + 1}`}
                // name={`amount${index}`}
                rules={[{ message: "Please enter an amount" }]}
              >
                <Input
                  placeholder="Enter amount"
                  onChange={(e) =>
                    handleFieldChange(index, "amount", e.target.value)
                  }
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={`Status ${index + 1}`}
                // name={`status${index}`}
                rules={[{ message: "Please select a status" }]}
              >
                <Select
                  placeholder="Select status"
                  size="large"
                  onChange={(value) =>
                    handleFieldChange(index, "status", value)
                  }
                >
                  <Option value="paid">Paid</Option>
                  <Option value="notPaid">Not Paid</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        ))}
        <Button type="dashed" onClick={addFields} block className="p-6">
          Add Fields
        </Button>

        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
            Submit
          </Button>
        </Form.Item>
      </Form>
      {/* </div> */}
    </Modal>
  );
};

export default AddInvoicesPopup;
