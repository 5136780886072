import { useEffect, useState } from "react";
import {
  Card,
  Col,
  Row,
  Typography,
  message,
  Button,
  Space,
  Empty,
  Modal,
} from "antd";
import {
  PlusOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  CloudDownloadOutlined,
} from "@ant-design/icons";

import withLayoout from "../../hocs/withLayoout";
import axios from "../../utils/axios";
import AddInvoicesPopup from "../../components/AddInvoicesPopup";
import FilterNames from "../../components/FilterNames";
import "./styles.scss";
import moment from "moment-timezone";
import { API_URL } from "../../constants.js";
import ls from "../../utils/localstorage";

const { confirm } = Modal;

function Invoices() {
  const { Title, Text } = Typography;
  const [invoices, setInvoices] = useState([]);
  const [filteredInvoices, setFilteredInvoices] = useState();
  const [addPopupVisibility, setAddPopupVisibility] = useState(false);
  const [singleInvoices, setSingleInvoices] = useState(null);
  const [filtersAdded, setFiltersAdded] = useState();

  const getInvoices = async () => {
    try {
      const response = await axios.get(`/invoice`);
      setInvoices(response?.data);
      console.log({ response: response.data });
    } catch (error) {
      console.log({ error });
      message.error("Couldn't fetch Invoices!");
    }
  };

  useEffect(() => {
    (async () => await getInvoices())();
    return () => {
      setInvoices(null);
    };
  }, [singleInvoices]);

  const handleClose = async (value) => {
    await getInvoices();
    setAddPopupVisibility(value);
  };

  const handleAddInvoices = () => {
    setAddPopupVisibility((s) => !s);
  };

  const handleDelete = (invoices) => {
    confirm({
      title: "Are you sure you want to delete this invoices?",
      icon: <ExclamationCircleOutlined />,
      content: "This action cannot be undone.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          await axios.delete(`/invoice/${invoices.invoiceNumber}`);
          message.success("Invoices deleted successfully!");
          setSingleInvoices(null);
        } catch (error) {
          message.error("Failed to delete the invoices");
          console.log({ error });
        }
      },
    });
  };

  const handleDownload = async (invoice) => {
    try {
      const token = ls.get("user")?.accessToken;
      const invoiceResponse = await fetch(
        API_URL + `/invoice/${invoice.invoiceNumber}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      const blob = await invoiceResponse.blob();

      if (blob) {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `invoice-${moment()}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      }
      message.success("Invoices Added Successfully!");
    } catch (e) {
      console.log("Error: " + e);

      message.error("Failed to Fetch invoice!");
    }
  };

  return (
    <>
      <div className="layout-content top-p">
        <Row gutter={[32, 0]}>
          <div className="button-container">
            <Space direction="horizontal" size={14}>
              <Text strong>Total Bori: </Text>
              <div> {(filteredInvoices?.bori || filteredInvoices?.bori === 0) ? filteredInvoices?.bori : (invoices?.bori === 0 || invoices?.bori) ? invoices?.bori : 0}</div>

              <Text strong>Total Tora : </Text>
              <div> {(filteredInvoices?.tora || filteredInvoices?.tora === 0) ? filteredInvoices?.tora : (invoices?.tora === 0 || invoices?.tora) ? invoices?.tora : 0}</div>

              <Text strong>Total Tora 25 : </Text>
              <div> {(filteredInvoices?.tora25 || filteredInvoices?.tora25 === 0) ? filteredInvoices?.tora25 : (invoices?.tora25 === 0 || invoices?.tora25) ? invoices?.tora25 : 0}</div>

              <Text strong>Total Tora 50 : </Text>
              <div> {(filteredInvoices?.tora50 || filteredInvoices?.tora50 === 0) ? filteredInvoices?.tora50 : (invoices?.tora50 === 0 || invoices?.tora50) ? invoices?.tora50 : 0}</div>

              <Text strong>Total Weight : </Text>
              <div> {(filteredInvoices?.weight || filteredInvoices?.weight === 0) ? filteredInvoices?.weight : (invoices?.weight === 0 || invoices?.weight) ? invoices?.weight : 0}</div>

              <Button
                onClick={handleAddInvoices}
                type="primary"
                icon={<PlusOutlined />}
              >
                Add
              </Button>
            </Space>
          </div>
          <Col xs={24} sm={24} md={24} lg={24} xl={32} className="mb-32">
            <Card bordered={false} className="criclebox cardbody h-full">
              <div className="project-ant">
                <div className="pl-24">
                  <Title level={1}>Invoices</Title>
                </div>
                <div>
                  <Row className="mr-10" gutter={[5, 0]}>
                    <div className="ant-filtertabs">
                      <div className="antd-pro-pages-dashboard-analysis-style-salesExtra">
                        <Space direction="horizontal" size={14}>
                          <Text strong>Filter:</Text>
                          <FilterNames
                            data={invoices.invoices}
                            setData={setFilteredInvoices}
                            isInvoices={true}
                            names="varieties"
                            setFiltersAdded={setFiltersAdded}
                            filtersAdded={filtersAdded}
                          />
                          <FilterNames
                            data={invoices.invoices}
                            setData={setFilteredInvoices}
                            isInvoices={true}
                            names="accountNames"
                            setFiltersAdded={setFiltersAdded}
                            filterAdded={filtersAdded}
                          />
                        </Space>
                      </div>
                    </div>
                  </Row>
                </div>
              </div>
              <div className="ant-list-box table-responsive">
                <table className="width-100">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Invoice Number</th>
                      <th>Type</th>
                      <th>Variety</th>
                      <th>Account Name</th>
                      <th>Description</th>
                      <th>Quantity</th>
                      <th>Quantity Type</th>
                      <th>Rate</th>
                      <th>Weight</th>
                      <th>Added by</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredInvoices
                      ? filteredInvoices?.invoices?.map((d, index) => (
                        <tr key={index}>
                          <td>
                            {moment(d.date)
                              .local()
                              .format("YYYY-MM-DD HH:mm:ss")}
                          </td>
                          <td>
                            <span className="text-xs font-weight-bold">
                              {d.invoiceNumber}
                            </span>
                          </td>
                          <td>
                            <span className="text-xs font-weight-bold">
                              {d.type}
                            </span>
                          </td>
                          <td>
                            <span className="text-xs font-weight-bold">
                              {d.variety}
                            </span>
                          </td>
                          <td>
                            <div>{d.accountName}</div>
                          </td>
                          <td>
                            <span>{d.description}</span>
                          </td>
                          <td>
                            <div>{d.quantity}</div>
                          </td>
                          <td>
                            <div>{d.quantityType}</div>
                          </td>
                          <td>
                            <div>{d.rate}</div>
                          </td>

                          <td>
                            <div>{d?.grossWeight}</div>
                          </td>
                          <td>
                            <div>{d.createdBy} </div>
                          </td>
                          <td className="d-flex">
                            <Space direction="horizontal" size={14}>
                              <Button
                                type="link"
                                icon={<CloudDownloadOutlined />}
                                onClick={() => handleDownload(d)}
                                size="small"
                              />
                              <Button
                                type="link"
                                danger
                                icon={<DeleteOutlined />}
                                onClick={() => handleDelete(d)}
                                size="small"
                              />
                            </Space>
                          </td>
                        </tr>
                      ))
                      : invoices?.invoices?.map((d, index) => (
                        <tr key={index}>
                          <td>
                            {moment(d.date)
                              .local()
                              .format("YYYY-MM-DD HH:mm:ss")}
                          </td>
                          <td>
                            <span className="text-xs font-weight-bold">
                              {d.invoiceNumber}
                            </span>
                          </td>
                          <td>
                            <span className="text-xs font-weight-bold">
                              {d.type}
                            </span>
                          </td>
                          <td>
                            <span className="text-xs font-weight-bold">
                              {d.variety}
                            </span>
                          </td>
                          <td>
                            <div>{d.accountName}</div>
                          </td>
                          <td>
                            <span>{d.description}</span>
                          </td>
                          <td>
                            <div>{d.quantity}</div>
                          </td>
                          <td>
                            <div>{d.quantityType}</div>
                          </td>
                          <td>
                            <div>{d.rate}</div>
                          </td>

                          <td>
                            <div>{d?.grossWeight}</div>
                          </td>
                          <td>
                            <div>{d.createdBy} </div>
                          </td>
                          <td className="d-flex">
                            <Space direction="horizontal" size={14}>
                              <Button
                                type="link"
                                icon={<CloudDownloadOutlined />}
                                onClick={() => handleDownload(d)}
                                size="small"
                              />

                              <Button
                                type="link"
                                danger
                                icon={<DeleteOutlined />}
                                onClick={() => handleDelete(d)}
                                size="small"
                              />
                            </Space>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                {!invoices.invoices?.length && (
                  <Empty className="p-3" description={"No Results "} />
                )}
              </div>
            </Card>
          </Col>
        </Row>
      </div>
      <AddInvoicesPopup
        visible={addPopupVisibility}
        onClose={handleClose}
        closePopup={setAddPopupVisibility}
        invoices={singleInvoices}
        setInvoices={setSingleInvoices}
      />
    </>
  );
}

export default withLayoout(Invoices);
