import React, { useEffect, useState } from "react";
import moment from "moment";
import { Modal, Button, DatePicker, Select, Input, Form, message } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import axios from "../../utils/axios.js";

const { Option } = Select;
const { TextArea } = Input;

const AddTransactionPopup = ({
  visible,
  onClose,
  closePopup,
  transaction,
  setTransactions,
  setFiltersAdded
}) => {
  const [accounts, setAccounts] = useState([]);
  const [debitSelected, setDebitSelected] = useState(
    transaction?.debitAccountId || null
  );
  const [creditSelected, setCreditSelected] = useState(
    transaction?.creditAccountId || null
  );

  const [form] = Form.useForm();

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get("/account/getAccounts");
        setAccounts(response?.data);
      } catch (error) {
        new Error("Error getting accounts!");
        console.log({ error });
      }
    })();

    if (transaction) {
      form.setFieldsValue({
        ...transaction,
        date: moment(transaction.date),
      });
    }

    return () => {
      setAccounts(null);
    };
  }, [form, transaction]);

  const handleFinish = async (values) => {
    values.date = moment(values.date).utc().toISOString();

    try {
      if (transaction) {
        await axios.patch(`/transaction/${transaction._id}`, values);
        message.success("Transaction Updated Successfully!");
      } else {
        await axios.post("/transaction", values);
        message.success("Transaction Added Successfully!");
      }

      onClose(false);
      form.resetFields();
      setDebitSelected(null);
      setCreditSelected(null);
      setTransactions(null);
      setFiltersAdded(null);
    } catch (error) {
      message.error("Couldn't process the transaction");
      console.log({ error });
    }
  };

  const handleFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    message.error("Please check the form fields and try again.");
  };

  const validateDebitOrCredit = (_, value) => {
    const debitFrom = form.getFieldValue("debitAccountId");
    const creditIn = form.getFieldValue("creditAccountId");
    const accountId = form.getFieldValue("accountId");

    if (debitFrom || creditIn || accountId) {
      return Promise.resolve();
    }
    if (transaction) {
      return Promise.reject(new Error("Account must be selected!"));
    }
    return Promise.reject(
      new Error("At least one of Debit From or Credit In must be selected!")
    );
  };

  const handleDebitChange = (value) => {
    setDebitSelected(value);
    form.setFieldsValue({
      creditIn: value === creditSelected ? null : creditSelected,
    });
  };

  const handleCreditChange = (value) => {
    setCreditSelected(value);
    form.setFieldsValue({
      debitFrom: value === debitSelected ? null : debitSelected,
    });
  };

  const filteredDebitOptions = accounts?.filter(
    (option) => option._id !== creditSelected
  );

  const filteredCreditOptions = accounts?.filter(
    (option) => option._id !== debitSelected
  );

  return (
    <Modal
      open={visible}
      title={transaction ? "Edit Transaction" : "Add Transaction"}
      onCancel={() => {
        closePopup(false);
        form.resetFields();
        setDebitSelected(null);
        setCreditSelected(null);
      }}
      footer={null}
      closeIcon={<CloseOutlined />}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleFinish}
        onFinishFailed={handleFinishFailed}
      >
        <Form.Item
          name="date"
          label="Date"
          initialValue={transaction ? moment(transaction.date) : moment()}
          rules={[{ required: true, message: "Please select the date!" }]}
        >
          <DatePicker style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          name="description"
          label="Description"
          rules={[{ required: true, message: "Please enter a description!" }]}
        >
          <TextArea placeholder="Enter description" rows={4} />
        </Form.Item>
        {transaction ? (
          <Form.Item
            name="accountId"
            label="Account"
            rules={[{ validator: validateDebitOrCredit }]}
          >
            <Select
              placeholder="Select an account"
              onChange={handleDebitChange}
              allowClear
              showSearch
            >
              {filteredDebitOptions?.map((option) => (
                <Option key={option._id} value={option._id}>
                  {option.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        ) : (
          <>
            <Form.Item
              name="debitAccountId"
              label="Debit From"
              rules={[{ validator: validateDebitOrCredit }]}
            >
              <Select
                placeholder="Select an account"
                onChange={handleDebitChange}
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {filteredDebitOptions?.map((option) => (
                  <Option key={option._id} value={option._id}>
                    {option.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="creditAccountId"
              label="Credited to"
              rules={[{ validator: validateDebitOrCredit }]}
            >
              <Select
                placeholder="Select an account"
                onChange={handleCreditChange}
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {filteredCreditOptions?.map((option) => (
                  <Option key={option._id} value={option._id}>
                    {option.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </>
        )}

        <Form.Item
          name="amount"
          label="Amount"
          rules={[
            { required: true, message: "Please enter the amount!" },
            { pattern: /^-?\d*\.?\d+$/, message: "Amount must be a number!" },
          ]}
        >
          <Input placeholder="Enter amount" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
            {transaction ? "Update" : "Submit"}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddTransactionPopup;
